import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { CompanyWrapper } from "./companyStyle"
import { EmailButton, MobileButton, PhoneButton } from "../shared/button/button"
// import person1 from "../../images/kuba.png"
// import person2 from "../../images/sambor.png"
// import person4 from "../../images/karolina.png"
// import person3 from "../../images/kaja.png"
// import person10 from "../../images/person2.png"
// import person11 from "../../images/marta.png"
// import person12 from "../../images/osoby/anna_niewczas.jpg"
// import imageNataliaNawrocka from "../../images/osoby/natalia_nawrocka.jpg"
// import imageMonikaKoska from "../../images/osoby/monika_koska.jpg"
// import imageKatarzynaAlagierska from "../../images/osoby/katarzyna_alagierska.jpg"
// import imageAngelikaWiecek from "../../images/osoby/andzelika_wiecek.jpg"

import img from "../../images/company_img.png"
import Tiles from "../shared/tiles/tiles"
import home from "../../images/home-green.png"
import lady from "../../images/user.png"
// import Submenu from "../shared/submenu/submenu"
import { injectIntl } from "gatsby-plugin-intl"
import CompanyTiles from "@/components/company/companyTiles"

function CompanyContainer({ intl }) {

  return (
    <CompanyWrapper fluid>
      <Container className="about-container">
        <Row className={"green-line"}>
          <Col xs="12" lg={4}>
            <h1 className="company-title">
              {intl.formatMessage({ id: "about.title" })}
            </h1>
            <h4 className="company-subtitle">
              {intl.formatMessage({ id: "about.content.bold" })}
            </h4>
            <p className="company-content">
              {intl.formatMessage({ id: "about.content" })}
            </p>
          </Col>
          <Col lg={3} className={"d-none d-lg-flex"} />
          <Col lg={5} className={"d-none d-lg-flex company-image"}>
            <img src={img} alt="firma" />
          </Col>
          {/*<Col xs="12" lg={3} className={"submenu-wrapper"}>
            <Submenu />
          </Col>*/}
        </Row>
      </Container>
      <Container fluid className="team-container">
        <Container>
          <Row>
            <Col xs="12" lg={6} className={"content-wrap"}>
              <h1 className="company-title">
                {intl.formatMessage({ id: "about.team.title" })}
              </h1>
              <p className="company-content team-content">
                {intl.formatMessage({ id: "about.team.content" })}
              </p>

              <div className={"buttons-desktop d-none d-lg-flex"}>
                <PhoneButton />
                <EmailButton />
              </div>
            </Col>
            {/*
           <Col xs={12} lg={6} className={"person-section"}>
              <Row className={"justify-content-lg-center"}>
                <Col className="person-item top-person" xs="12" lg={6}>
                  <div className="avatar">
                    <img alt="zespół" src={person1} />
                  </div>
                  <div className="content">
                    <div>
                      <h4>
                        Jakub Gorazdowski
                      </h4>
                      <p>
                        {intl.formatMessage({ id: "about.first.person.job" })}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="person-item" xs="12" lg={6}>
                  <div className="avatar">
                    <img alt="zespół" src={person12} />
                  </div>
                  <div className="content">
                    <div>
                      <h4>
                        Anna Niewczas
                      </h4>
                      <p style={{lineHeight: 1}}>
                        {intl.formatMessage({ id: "about.twelve.person.job" })}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="person-item top-person" xs="12" lg={6}>
                  <div className="avatar">
                    <img alt="zespół" src={person2} />
                  </div>
                  <div className="content">
                    <div>
                      <h4>
                        {intl.formatMessage({ id: "about.second.person" })}
                      </h4>
                      <p>
                        {intl.formatMessage({ id: "about.second.person.job" })}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>

                <Col className="person-item" xs="12" lg={6}>
                  <div className="avatar">
                    <img alt="zespół" src={imageMonikaKoska} />
                  </div>
                  <div className="content">
                    <div>
                      <h4>Monika Kośka</h4>
                      <p>
                        {intl.formatMessage({ id: "about.thirteen.person.job" })}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="person-item" xs="12" lg={6}>
                  <div className="avatar">
                    <img alt="zespół" src={imageNataliaNawrocka} />
                  </div>
                  <div className="content">
                    <div>
                      <h4>{intl.formatMessage({ id: "about.ten.person" })}</h4>
                      <p>
                        {intl.formatMessage({ id: "about.ten.person.job" })}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>


                <Col className="person-item" xs="12" lg={6}>
                  <div className="avatar">
                    <img alt="zespół" src={imageAngelikaWiecek} />
                  </div>
                  <div className="content">
                    <div>
                      <h4>Andżelika Więcek</h4>
                      <p style={{lineHeight: 1}}>
                        {intl.formatMessage({ id: "afterSalesService.title" })}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="person-item" xs="12" lg={6}>
                  <div className="avatar">
                    <img alt="zespół" src={imageKatarzynaAlagierska} />
                  </div>
                  <div className="content">
                    <div>
                      <h4>Katarzyna Alagierska</h4>
                      <p style={{lineHeight: 1}} dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "about.position.arrangement" })}}>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>

            </Col>
            */}
            <Col className="buttons-wrapper d-flex d-lg-none" xs="12">
              <Col className="button-item write" xs="6">
                <MobileButton color="white" bg="#5D5D5D" innerText="Napisz" />
              </Col>
              <Col className="button-item call" xs="6">
                <MobileButton color="white" bg="#94C835" innerText="Zadzwoń" />
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="exp-container d-xl-none">
        <Row>
          <Col xs="12">
            <h4 className="exp-subtitle">
              {intl.formatMessage({ id: "about.info.mobile.title" })}
            </h4>
            <p className="exp-content">
              {intl.formatMessage({ id: "about.info.mobile.content" })}
            </p>
          </Col>
        </Row>
      </Container>
      <CompanyTiles />
    </CompanyWrapper>
  )
}

export default injectIntl(CompanyContainer)
